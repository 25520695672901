<template>
  <div class='indexbox'>
    <el-form ref="form" :model="form" :rules="rules" class="formbox">
      <div class="imgbox">
        <img src="../../assets/img/logo.png" alt="">
        <p>Reset your password</p>
      </div>
      <el-form-item prop="password">
        <span class="form_tit">Password*</span>
        <el-input v-model="form.password" type="password" maxlength="18" clearable show-password></el-input>
      </el-form-item>
      <el-form-item prop="re_password">
        <span class="form_tit">Confirm Password*</span>
        <el-input v-model="form.re_password" type="re_password" maxlength="18" clearable show-password></el-input>
      </el-form-item>
      <div class="password"></div>
      <el-form-item>
        <el-button type="primary" @click.native.prevent="onSubmit" :loading='loading'>Confirm</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        form: {
          id: '',
          password: '',
          re_password: '',
        },
        rules: {
          password: [
            { required: true, trigger: "blur", message: 'Please Enter Password' }
          ],
          re_password: [
            { required: true, trigger: "blur", message: 'Please Enter Confirm  Password' }
          ],
        },
        loading: false,
      };
    },
    computed: {},
    watch: {},
    methods: {
      onSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true
            this.$post({
              url: "/app/auth/forgetPassword",
              data: this.form,
              success: (res) => {
                this.$message({
                  message: res.msg,
                  type: 'success'
                });
                setTimeout(() => {
                  this.$router.push('login')
                  this.loading = false
                }, 1500);
              },
              tip: () => {
                setTimeout(() => {
                  this.loading = false
                }, 1500);
              },
            })
          }
        });
      }
    },
    created() {
      if (this.$route.query.id) {
        this.form.id = this.$route.query.id
      }
    }
  }
</script>
<style lang='scss' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 80px;
    .formbox {
      width: 480px;
      padding: 10px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .imgbox {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 310px;
          height: 160px;
        }
        p {
          width: 100%;
          text-align: center;
          color: #3b3b3b;
          font-size: 26px;
          font-weight: 600;
          font-family: PingFang SC;
          padding: 40px 0;
        }
      }
      .password {
        width: 100%;
        > a {
          color: $color;
          font-size: 16px;
        }
        margin-bottom: 40px;
      }

      /deep/.el-form-item {
        width: 100%;
        margin-bottom: 30px !important;
        .el-input {
          .el-input__inner {
            height: 50px;
          }
        }
        .el-form-item__content {
          width: 100%;
          .el-button {
            width: 100%;
            height: 48px;
            background: $color !important;
            border-radius: 5px;
            border-color: $color;
          }
        }
      }

      .registerbox {
        width: 100%;
        text-align: center;
        color: #3b3b3b;
        font-size: 16px;
        padding-top: 10px;
        a {
          color: $color;
          font-size: 16px;
        }
      }
    }
  }
</style>